module.exports = {
  chapeco: { name: 'Chapecó', slug: 'chapeco' },

  coredecChapeco: { name: 'Coordenadoria de Chapecó', slug: 'coredec-chapeco' },
  coredecMaravilha: { name: 'Coordenadoria de Maravilha', slug: 'coredec-maravilha' },
  coredecXanxere: { name: 'Coordenadoria de Xanxere', slug: 'coredec-xanxere' },
  coredecMiguel: { name: 'Coordenadoria de São Miguel', slug: 'coredec-miguel' },
  // COREDEC CHAPECÓ
  aguasdechapeco: { name: 'Águas de Chapecó', slug: 'aguas-de-chapeco' },
  aguasfrias: { name: 'Águas Frias', slug: 'aguas-frias' },
  arvoredo: { name: 'Arvoredo', slug: 'arvoredo' },
  caxambu: { name: 'Caxambu do Sul', slug: 'caxambu-do-sul' },
  cordilheira: { name: 'Cordilheira Alta', slug: 'cordilheira-alta' },
  coronelfreitas: { name: 'Coronel Freitas', slug: 'coronel-freitas' },
  formosadosul: { name: 'Formosa do Sul', slug: 'formosa-do-sul' },
  guatambu: { name: 'Guatambu', slug: 'guatambu' },
  irati: { name: 'Irati', slug: 'irati' },
  jardinopolis: { name: 'Jardinópolis', slug: 'jardinopolis' },
  novaerechim: { name: 'Nova Erechim', slug: 'nova-erechim' },
  novaitaberaba: { name: 'Nova Itaberaba', slug: 'nova-itaberaba' },
  paial: { name: 'Paial', slug: 'paial' },
  pinhalzinho: { name: 'Pinhalzinho', slug: 'pinhalzinho' },
  planaltoalegre: { name: 'Planalto Alegre', slug: 'planalto-alegre' },
  quilombo: { name: 'Quilombo', slug: 'quilombo' },
  santiagodosul: { name: 'Santiago do Sul', slug: 'santiago-do-sul' },
  saocarlos: { name: 'São Carlos', slug: 'sao-carlos' },
  serraalta: { name: 'Serra Alta', slug: 'serra-alta' },
  sulbrasil: { name: 'Sul Brasil', slug: 'sul-brasil' },
  uniaodooeste: { name: 'União do Oeste', slug: 'uniao-do-oeste' },
  // COREDEC MARAVILHA
  bomjesusdooeste: { name: 'Bom Jesus do Oeste', slug: 'bom-jesus-do-oeste' },
  caibi: { name: 'Caibi', slug: 'caibi' },
  campoere: { name: 'Campo Erê', slug: 'campo-ere' },
  cunhapora: { name: 'Cunha Porã', slug: 'cunha-pora' },
  cunhatai: { name: 'Cunhataí', slug: 'cunhatai' },
  flordosertao: { name: 'Flor do Sertão', slug: 'flor-do-sertao' },
  iraceminha: { name: 'Iraceminha', slug: 'iraceminha' },
  maravilha: { name: 'Maravilha', slug: 'maravilha' },
  modelo: { name: 'Modelo', slug: 'modelo' },
  palmitos: { name: 'Palmitos', slug: 'palmitos' },
  riqueza: { name: 'Riqueza', slug: 'riqueza' },
  romelandia: { name: 'Romelândia', slug: 'romelandia' },
  saltinho: { name: 'Saltinho', slug: 'saltinho' },
  santaterrezinha: { name: 'Santa Terezinha do Progresso', slug: 'santa-terrezinha-do-progresso' },
  saomiguel: { name: 'São Miguel da Boa Vista', slug: 'sao-miguel-da-boa-vista' },
  saudades: { name: 'Saudades', slug: 'saudades' },
  tigrinhos: { name: 'Tigrinhos', slug: 'tigrinhos' },
  // COREDEC SÃO MIGUEL
  anchieta: { name: 'Anchieta', slug: 'anchieta' },
  bandeirantes: { name: 'Bandeirante', slug: 'bandeirantes' },
  barrabonita: { name: 'Barra Bonita', slug: 'barra-bonita' },
  belmonte: { name: 'Belmonte', slug: 'belmonte' },
  descanso: { name: 'Descanso', slug: 'descanso' },
  dionisio: { name: 'Dionísio Cerqueira', slug: 'dionisio-cerqueira' },
  guaraciaba: { name: 'Guaraciaba', slug: 'guaraciaba' },
  guarujadosul: { name: 'Guarujá do Sul', slug: 'guaruja-do-sul' },
  ipora: { name: 'Iporã do Oeste', slug: 'ipora-do-oeste' },
  itapiranga: { name: 'Itapiranga', slug: 'itapiranga' },
  mondai: { name: 'Mondaí', slug: 'mondai' },
  palmasola: { name: 'Palma Sola', slug: 'palma-sola' },
  paraiso: { name: 'Paraíso', slug: 'paraiso' },
  princesa: { name: 'Princesa', slug: 'princesa' },
  santahelena: { name: 'Santa Helena', slug: 'santa-helena' },
  saojoaodooeste: { name: 'São João do Oeste', slug: 'sao-joao-do-oeste' },
  saojosedocedro: { name: 'São José do Cedro', slug: 'sao-jose-do-cedro' },
  saomigueldooeste: { name: 'São Miguel do Oeste', slug: 'sao-miguel-do-oeste' },
  tunapolis: { name: 'Tunápolis', slug: 'tunapolis' },
  // COREDEC XANXERE
  abelardoluz: { name: 'Abelardo Luz', slug: 'abelardo-luz' },
  bomjesus: { name: 'Bom Jesus', slug: 'bom-jesus' },
  coronelmartins: { name: 'Coronel Martins', slug: 'coronel-martins' },
  entrerios: { name: 'Entre Rios', slug: 'entre-rios' },
  faxinaldosguedes: { name: 'Faxinal dos Guedes', slug: 'faxinal-dos-guedes' },
  galvao: { name: 'Galvão', slug: 'galvao' },
  ipuacu: { name: 'Ipuaçu', slug: 'ipuacu' },
  jupia: { name: 'Jupiá', slug: 'jupia' },
  lajeadogrande: { name: 'Lajeado Grande', slug: 'lajeado-grande' },
  marema: { name: 'Marema', slug: 'marema' },
  novohorizonte: { name: 'Novo Horizonte', slug: 'novo-horizonte' },
  ouroverde: { name: 'Ouro Verde', slug: 'ouro-verde' },
  passosmaia: { name: 'Passos Maia', slug: 'passos-maia' },
  ponteserrada: { name: 'Ponte Serrada', slug: 'ponte-serrada' },
  saobernardino: { name: 'São Bernardino', slug: 'sao-bernardino' },
  saodomingos: { name: 'São Domingos', slug: 'sao-domingos' },
  saolourencodooeste: { name: 'São Lourenço do Oeste', slug: 'sao-lourenco-do-oeste' },
  vargeao: { name: 'Vargeão', slug: 'vargeao' },
  xanxere: { name: 'Xanxerê', slug: 'xanxere' },
  xaxim: { name: 'Xaxim', slug: 'xaxim' }

};
